import { SET_GLOBAL_DATETIMERANGE_SELECTION } from '../actionTypes';

/**
 * @typedef {Object} PayloadValues
 * @property {Date} from
 * @property {Date} to
 * @property {string} rangeToggle
 * @property {string} selectedData
 * @typedef {{ key: string; values: PayloadValues; }} ActionPayload
 * @typedef {{ type: string; payload: ActionPayload; }} Action
 * @typedef {{ [key: string]: PayloadValues; }} State
 *
 * @description
 * This reducer function updates the stored state values of a globally scoped
 * datetimerange selection.
 *
 * @param {State} state
 * @param {Action} param1
 * @returns {State}
 */
const dateTimeRangeSelection = (state = {}, { type, payload }) => {
  if (type === SET_GLOBAL_DATETIMERANGE_SELECTION) {
    state[payload.key] = payload.values;
  }

  return state;
};

export const SELECTION_ACTIVITIES_DATERANGE =
  'activities-datetimerange-selection';

export default dateTimeRangeSelection;
