import { combineReducers } from 'redux';
import jwt from './jwt';
import currentUser from './currentUser';
import currentImages from './currentImages';
import partImages from './partImages';
import deviceSetup from './device-setup';
import deviceLanguage from './device-language';
import activity from './activity';
import mainActivity from './main-activity';
import selectedPivot from './selected-pivot';
import isDisabledSaveButton from './isdisabled-savebutton';
import touchInput from './touch-input';
import closeCharacteristicSliderIcon from './closeCharacteristicSliderIcon';
import metaInput from './metainput';
import dashboard from './dashboard';
import dateTimeRangeSelection from './dateTimeRangeSelection';
import scaninput from './scaninput';
import selectedbranches from './selected-branches';
import datagrid from './datagrid';
import activityFormReducer from '../slices/activityForm';
import pwaReducer from '../slices/pwa';
import dashboardReducer from '../../components/dashboard/dashboard-reducer';
import appSettingsReducer from '../slices/appSettings';
import selectedsubsidiaries from './selected-subsidiaries';
import addOrderReducer from '../../pages/orders/add/add-order-reducer';
import orderFormReducer from '../../pages/orders/manage/order-form-reducer';

export default combineReducers({
  jwt,
  currentUser,
  partImages,
  currentImages,
  deviceSetup,
  deviceLanguage,
  touchInput,
  activity,
  mainActivity,
  selectedPivot,
  isDisabledSaveButton,
  closeCharacteristicSliderIcon,
  metaInput,
  dashboard,
  dateTimeRangeSelection,
  scaninput,
  datagrid,
  selectedbranches: selectedbranches,
  selectedsubsidiaries: selectedsubsidiaries,
  activityForm: activityFormReducer,
  pwa: pwaReducer,
  dashboardV2: dashboardReducer,
  appSettings: appSettingsReducer,
  addOrder: addOrderReducer,
  orderForm: orderFormReducer,
});
